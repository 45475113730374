<template>
  <div class="col">
    <el-card v-loading="$store.getters['topics/usersLoad']">
      <!--  //! start header strategy  -->
      <template #header>
        <div class="main_header_wrapper">
          <div class="part_one">
            <i class="fa fa-database data_icon"></i>
            <p class="text_wrapping">
              {{ local.level1 }}
            </p>
          </div>
          <div class="part_two">
            <l-button
              class="btn_add_strategy"
              @click="open_strategy_dialog(1, local)"
            >
              <el-icon class="el-icon-plus btn_icon"></el-icon>
              <span class="btn_text">{{ localization("Add Version") }}</span>
            </l-button>
          </div>
        </div>
        <div class="hidden-md-only hidden-lg-only hidden-xl-only">
          <Info :items="localForInfo" />
        </div>
      </template>
      <!--  //! end header strategy  -->

      <!--//! start strategy wrapping  -->
      <!--
        <el-tree :data="local.children" default-expand-all>
       -->
      <el-tree :data="local.children" default-expand-all>
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <div class="lang_text">{{ data.title }}</div>

          <!--//? start main wrapper for strategy  -->
          <div class="main_wrapping_for_strategy">
            <!-- //? start part one  -->
            <div class="part_one_text" v-if="node.level == 2">
              <i class="fa fa-database text_icon_2"></i>
              <p class="text_words_2">{{ data.level1 }}</p>
              <!-- <l-button
                outline
                round
                size="sm"
                :type="data.removed == 0 ? 'success' : 'danger'"
              >

                {{ data.removed == 0 ? "info" : "inactive" }}
              </l-button> -->
            </div>
            <!-- //? start part two  -->
            <div class="part_two_text" v-if="node.level == 2">
              <l-button
                class="active_button"
                outline
                round
                size="sm"
                :type="data.removed == 0 ? 'success' : 'danger'"
              >
                {{
                  data.removed == 0
                    ? `${localization("Info")}`
                    : `${localization("Inactive")}`
                }}
              </l-button>
              <a
                v-tooltip.top-center="`${localization('Edit strategy')}`"
                @click="open_strategy_dialog(2, data)"
                class="edit_strategy"
              >
                <i class="fa fa-edit"></i>
              </a>
              <a
                v-tooltip.top-center="
                  data.removed == 0
                    ? `${localization('Delete strategy')}`
                    : `${localization('Active strategy')}`
                "
                @click="remove(data.id, data.removed)"
                class="edit_strategy delete"
              >
                <i v-if="data.removed == 0" class="fa fa-close "></i>
                <i v-else class="fa fa-check text-success"></i>
              </a>
            </div>
            <!--//? end main wrapper for strategy  -->
          </div>
        </div>
      </el-tree>
      <!--//// end strategy wrapping  -->
    </el-card>

    <!-- // * Add Version  -->
    <el-dialog
      :title="
        type == 1
          ? `${localization('Add Version')}`
          : `${localization('Edit Version')}`
      "
      width="50%"
      :visible.sync="strategy_Dialog"
      top="4vh"
      class="add_strategy_dialog_user"
    >
      <el-form
        :model="strategy"
        ref="ruleForm"
        :rules="rules"
        label-position="top"
        @submit.prevent="save"
      >
        <el-form-item
          :label="`${localization('Text')}`"
          label-width="100px"
          prop="level1"
        >
          <el-input v-model="strategy.level1" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          :label="`${localization('Language')}`"
          label-width="100px"
          prop="language"
        >
          <el-select
            v-model="strategy.language"
            :placeholder="`${localization('Please select language')}`"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in languages"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="
          dialog-footer
          user_profile_dialog_footer

        "
      >
        <el-button
          class="text-center text-capitalize"
          @click="strategy_Dialog = false"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          class="
            text-center
            text-capitalize
          "
          type="primary"
          @click="save"
          >{{ localization("Save") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Info from "../../components/Cards/Info";
import "element-ui/lib/theme-chalk/display.css";
import Swal from "sweetalert2";

export default {
  components: {
    Info
  },
  data: () => ({
    languages: [],
    strategy_Dialog: false,
    strategy: {},
    rules: {
      level1: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "blur"
        }
      ],
      classification: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "change"
        }
      ],
      topic_id: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "change"
        }
      ],
      pattern: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "change"
        }
      ],
      age_group: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "change"
        }
      ],
      language: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "change"
        }
      ],
      priority: [
        {
          required: true,
          message: "Please this field is required",
          trigger: "blur"
        }
      ]
    },
    type: null
  }),
  computed: {
    id() {
      return this.$route.params.id;
    },
    local() {
      return this.$store.getters["strategies/strategy"];
    },
    localForInfo() {
      return Object.entries(this.local)
        .map(([key, value]) => {
          return { key, value };
        })
        .filter(
          item =>
            item.key !== "parent_id" &&
            item.key !== "id" &&
            item.key !== "old_id" &&
            item.key !== "removed" &&
            item.key !== "isEnabled" &&
            item.key != "children"
        );
    }
  },
  mounted() {
    this.Refresh();
  },
  methods: {
    Refresh() {
      this.$store.dispatch("strategies/strategy", { id: this.id }).then(_ => {
        this.languages = this.$store.getters["strategies/languages"];
      });
    },
    save() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            this.$store
              .dispatch("strategies/AddStrategies", { query: this.strategy })
              .then(_ => {
                this.Refresh({});
                this.strategy_Dialog = false;
              });
          } else {
            this.$store
              .dispatch("strategies/UpdateStrategies", {
                id: this.strategy.id,
                query: this.strategy
              })
              .then(_ => {
                this.Refresh({});
                this.strategy_Dialog = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    remove(id, removed) {
      Swal.fire({
        // title: "Are you sure?",
        text:
          removed == 0
            ? `Are you sure you want to delete this version ?`
            : "Are you sure you want to Active this version ?",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: "Confirm",
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store.dispatch("strategies/delete", id).then(_ => {
            this.Refresh(null);
            Swal.fire({
              // title: removed == 0 ? `Deleted` : "Activated",
              text:
                removed == 0
                  ? "Version has been deleted."
                  : "Version has been activated.",
              icon: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false,
              type: "success"
            });
          });
        }
      });
    },
    open_strategy_dialog(type, item) {
      delete item.children;
      delete item.language_name;
      delete item.language_id;
      this.strategy = item;
      if (type == 1) {
        this.strategy.parent_id = parseInt(this.id);
        this.strategy.level1 = "";
        delete item.id;
      }
      this.Refresh();
      this.strategy_Dialog = true;
      this.type = type;
    }
  }
};
</script>

<style scoped lang="scss">
// ! main header style
.main_header_wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 10px 0;
  @media (min-width: 320px) and (max-width: 767px) {
    display: block;
  }
  .part_one {
    display: flex;
    gap: 10px;

    .data_icon {
      color: #9d9d9d;
    }
    .text_wrapping {
      color: #777;
      margin-bottom: 0;
      font-size: 14px;
      line-height: 1.7;
    }
  }
  .part_two {
    @media (min-width: 320px) and (max-width: 767px) {
      margin: 1rem 0;
    }
    .btn_add_strategy {
      display: flex;
      min-width: 160px;
      gap: 5px;
      background-color: #23ccef;
      color: #fff;
      border: 1px solid #23ccef;
      transition: 0.3s all;
      &:hover {
        opacity: 0.7;
      }
    }
    // 1px solid #23CCEF
  }
}

// ! main strategy style
::v-deep .el-card__body {
  padding: 0;
}
::v-deep .el-tree-node {
  // white-space: unset !important;
  outline: 0;
}
::v-deep .el-tree-node__content {
  height: auto !important;
  // padding-left: 0 !important;
  padding: 10px !important;
  // background: #000;
  display: block;
}
::v-deep .el-tree-node__children {
  height: auto !important;
}
::v-deep .el-tree-node__expand-icon {
  display: none;
}
.lang_text {
  // padding-left: 20px !important;
  // padding: 15px 0 15px 20px;
  // background: #000;
  // width: 100%;
}
.custom-tree-node {
  word-break: break-word;
  white-space: pre-wrap;
  color: #777;
  padding-left: 20px;
  // padding: 20px;
  // background: red;
}
.main_wrapping_for_strategy {
  // background: #eee;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 10px;
  @media (min-width: 320px) and (max-width: 767px) {
    display: block;
  }
  .part_one_text {
    display: flex;
    gap: 10px;
    @media (min-width: 320px) and (max-width: 767px) {
      margin-bottom: 20px;
    }
    .text_icon_2 {
      color: #9d9d9d;
    }
    .text_words_2 {
      color: #777;
      margin-bottom: 0;
      font-size: 14px;
      line-height: 1.7;
    }
  }
  .part_two_text {
    display: flex;
    align-items: center;
    min-width: 200px;
    justify-content: space-around;
    .active_button {
      display: block;
    }
    .edit_strategy {
      display: block;
      width: 30px;
      height: 30px;
      background-color: #fff;
      font-size: 14px;
      text-align: center;
      line-height: 30px;
      padding: 1px;
      border-radius: 50%;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2), 0 0 5px -2px rgba(0, 0, 0, 0.1);
      transition: 0.3s all;
      color: #777;
      &:hover {
        background-color: #23ccef;
        color: #fff;
      }
      // &.delete {
      //   &:hover {
      //     background-color: #f56c6c;
      //     color: #fff;
      //   }
      // }
    }
  }
}

.user_profile_dialog_footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  @media (min-width: 320px) and (max-width: 767px) {
    justify-content: center;
  }
}
</style>
<style lang="scss">
.add_strategy_dialog_user {
  .el-dialog {
    @media (min-width: 320px) and (max-width: 767px) {
      width: 90% !important;
    }
  }
}
</style>
